import { blogAPI } from './helper'
import { Blog } from '../types'

export const getBlog = async (slug: string) => {
  const { data } = await blogAPI<{ data: Blog }>({
    path: `/pdpa/blogs/${slug}`,
    method: 'get',
  })

  return data.data
}

import React, { useState, useEffect } from 'react'
import {
  Flex,
  Grid,
  Text,
  Input,
  Button,
  InputLeftElement,
  InputGroup,
  Icon,
  CircularProgress,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useMediaQuery } from 'react-responsive'

import { useBlogs } from '../services'
import { useBlogsFilterContext } from '../components/FilterProvider'
import { Blogs, BlogMeta } from '../types'

import { Footer, Header, Contact } from '@/components/Landing'
import { Container } from '@/components/Landing/shared'
import { BlogItem } from '@/components/Landing/Blog'
import { Pagination } from '@/components/index'

export const BlogsContainer = () => {
  const [search, setSearch] = useState<string>('')
  const [blogs, setBlogs] = useState<{ data: Blogs[]; meta: BlogMeta }>()
  const isMediumPlusDesktop = useMediaQuery({ minWidth: 1280 })

  const { meta, setFilter } = useBlogsFilterContext()
  const { data } = useBlogs(meta)

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault()
    setFilter('search', search)
  }

  const handleChangePage = async (page: number) => {
    setFilter('page', page)

    if (isMediumPlusDesktop) return

    const blogHeader = document.querySelector('#blog-header')
    if (blogHeader) {
      blogHeader.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (data) {
      setBlogs(data)
    }
  }, [JSON.stringify(data)])

  return (
    <div css={{ overflow: 'hidden' }}>
      <Header isAltStyle />
      <Grid
        h="356px"
        pt="60px"
        overflow="hidden"
        background="no-repeat top/cover url(/images/Landing/banner-bg.jpg)"
        boxSizing="unset"
        justifyContent="center"
        alignContent="start"
      >
        <Text
          as="h1"
          color="white"
          fontSize="48px"
          pt="83px"
          mb="32px"
          textAlign="center"
        >
          PDPA Blogs
        </Text>
        <form onSubmit={handleSearch}>
          <Grid
            gridTemplateColumns={{ base: '250px 60px', lg: '500px 96px' }}
            gap="4"
          >
            <InputGroup>
              <InputLeftElement>
                <Icon as={SearchIcon} name="search" color="gray.300" />
              </InputLeftElement>
              <Input
                size="md"
                bg="white"
                placeholder="ค้นหาเนื้อหาที่เกี่ยวข้อง"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </InputGroup>
            <Button size="md" colorScheme="primary" type="submit">
              ค้นหา
            </Button>
          </Grid>
        </form>
      </Grid>

      <Container css={{ padding: '96px 0 48px', position: 'relative' }}>
        <div id="blog-header" css={{ position: 'absolute', top: -50 }} />
        <div>
          {blogs?.data.length ? (
            <div
              css={{
                display: 'grid',
                gap: '24px 16px',
                gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))',
              }}
            >
              {blogs?.data.map((blog) => (
                <BlogItem
                  key={blog.title}
                  image={blog.image_1.url}
                  title={blog.title}
                  date={blog.published_at}
                  link={`/blogs/${blog.slug}`}
                  tags={blog.tags}
                />
              ))}
            </div>
          ) : (
            <Flex justify="center">
              {blogs?.meta.search ? (
                <Text color="gray.600">ไม่พบผลการค้นหา</Text>
              ) : (
                <CircularProgress isIndeterminate color="brand" />
              )}
            </Flex>
          )}
        </div>
        {blogs?.data.length ? (
          <Flex justify="center" mt={{ base: '48px', lg: '60px' }}>
            <Pagination
              page={blogs.meta.page}
              total={blogs.meta.total}
              onChangePage={handleChangePage}
            />
          </Flex>
        ) : null}
      </Container>
      <Contact />
      <Footer />
    </div>
  )
}

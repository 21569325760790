import { useQuery } from 'react-query'

import { blogAPI } from './helper'
import { FilterParams, BlogMeta, Blogs } from '../types'
import { BLOGS } from '../constants/queryKeys'

export const useBlogs = (meta: FilterParams) => {
  const params = { ...meta }

  return useQuery({
    queryKey: [BLOGS, params],
    queryFn: async () => {
      const { data } = await blogAPI<{
        data: Blogs[]
        meta: BlogMeta
      }>({
        path: `/pdpa/blogs`,
        method: 'get',
        params,
      })

      return {
        data: data.data,
        meta: data.meta,
      }
    },
  })
}

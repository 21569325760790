import { AxiosRequestConfig } from 'axios'

import envObj from '@/config/env'
import { fetchAPIv2 } from '@/lib/api'

type BlogApiProps = {
  path: string
} & Omit<AxiosRequestConfig, 'baseURL'>

export function blogAPI<T = any>(props: BlogApiProps) {
  const { path, ...options } = props

  return fetchAPIv2<T>({
    url: envObj.BLOG_API_URL,
    path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': envObj.BLOG_API_KEY,
    },
    responseType: 'json',
    ...options,
  })
}

import React from 'react'
import { noop } from 'lodash'
import { Box, Flex, Grid, IconButton, Button, Icon } from '@chakra-ui/react'
import {
  CgChevronLeft,
  CgChevronRight,
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
} from 'react-icons/cg'

const BUTTON_TO_SHOW = 6

const Pagination = (props) => {
  const { page, total, onChangePage = noop, perPage = 12 } = props

  const totalPage = Math.ceil(total / perPage)

  const renderButton = () => {
    if (totalPage <= BUTTON_TO_SHOW) {
      return Array(totalPage)
        .fill()
        .map((_, idx) => {
          const isActive = page === idx + 1

          return (
            <Button
              key={idx}
              {...buttonStyle(isActive)}
              onClick={() => onChangePage(idx + 1)}
            >
              {idx + 1}
            </Button>
          )
        })
    }

    const pages = []

    if (page + BUTTON_TO_SHOW - totalPage >= 2) {
      const numberVisibleInRange = totalPage - BUTTON_TO_SHOW
      pages.push(numberVisibleInRange)
      pages.push('. . .')

      for (let i = totalPage - 4; i <= totalPage; i += 1) {
        pages.push(i)
      }
    } else {
      if (page === 1) {
        for (let i = page; i <= page + 2; i += 1) {
          pages.push(i)
        }
      } else {
        for (let i = page - 1; i <= page + 1; i += 1) {
          pages.push(i)
        }
      }

      pages.push('. . .')

      for (let i = totalPage - 2; i <= totalPage; i += 1) {
        pages.push(i)
      }
    }

    return pages.map((p) => {
      if (p === '. . .') {
        return <Box fontWeight="300">{p}</Box>
      }

      return (
        <Button
          key={p}
          {...buttonStyle(page === p)}
          onClick={() => onChangePage(p)}
        >
          {p}
        </Button>
      )
    })
  }

  return (
    <Flex justify="center">
      <Flex align="center">
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleLeft} />}
          isDisabled={page === 1}
          onClick={() => onChangePage(1)}
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronLeft} />}
          mr="8px"
          isDisabled={page === 1}
          onClick={() => onChangePage(page - 1)}
        />
        <Grid gridAutoFlow="column" gap="8px" alignItems="center">
          {renderButton()}
        </Grid>
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronRight} />}
          ml="8px"
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(page + 1)}
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleRight} />}
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(totalPage)}
        />
      </Flex>
    </Flex>
  )
}

export default Pagination

const buttonStyle = (isActive) => {
  return {
    width: { base: '40px', lg: '56px' },
    height: { base: '40px', lg: '56px' },
    colorScheme: 'primary',
    fontSize: '18px',
    variant: 'ghost',
    color: 'black',
    fontWeight: 300,
    borderRadius: '8px',
    ...(isActive && {
      fontWeight: 500,
      color: 'white',
      variant: 'solid',
    }),
    _hover: {
      backgroundColor: 'primary.50',
      color: 'primary.400',
    },
  }
}

const arrowButtonStyle = {
  w: '40px',
  h: '40px',
  minW: '0',
  fontSize: '28px',
  variant: 'ghost',
  color: 'primary.400',
}

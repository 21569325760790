import { blogAPI } from './helper'
import { Blogs } from '../types'

export const getTag = async (tagId: string) => {
  const { data } = await blogAPI<{ data: Blogs[] }>({
    path: `/pdpa/blogs`,
    method: 'get',
    params: {
      tag_id: tagId,
      page: -1,
      per_page: -1,
    },
  })

  return data.data
}

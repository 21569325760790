import { ReactNode, useCallback, useState } from 'react'

import { createCtx } from '@/lib/helpers'
import { FilterParams } from '../types'

type FilterContext = {
  meta: FilterParams
  setFilter: SetFilterFunction
  setFilters: SetFiltersFunction
  resetFilters: () => void
}

type SetFilterFunction = <T extends unknown>(key: string, value: T) => void
type SetFiltersFunction = <T extends object>(meta: FilterParams & T) => void

const filterContext = createCtx<FilterContext>()
const [, Provider] = filterContext
export const [useBlogsFilterContext] = filterContext

type FilterProviderProps = {
  children: ReactNode
  initialMeta?: FilterParams
}

export const BlogFilterProvider = (props: FilterProviderProps) => {
  const { children, initialMeta } = props

  const initialState = {
    page: 1,
    per_page: 12,
    search: '',
    ...initialMeta,
  }

  const [meta, setMeta] = useState<FilterParams>(initialState)

  const setFilter = useCallback<SetFilterFunction>((key, value) => {
    setMeta((curState) => ({
      ...curState,
      [key]: value,
      ...(key !== 'page' && { page: 1 }),
    }))
  }, [])

  const setFilters = useCallback<SetFiltersFunction>((meta) => {
    setMeta((curState) => ({ ...curState, ...meta }))
  }, [])

  const resetFilters = useCallback(() => {
    setMeta(initialState)
  }, [])

  const contextValue = {
    meta,
    setFilter,
    setFilters,
    resetFilters,
  }

  return <Provider value={contextValue}>{children}</Provider>
}

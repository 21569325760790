import React, { Fragment } from 'react'
import { NextSeo } from 'next-seo'

import { BlogFilterProvider } from '../components/FilterProvider'
import { BlogsContainer } from '../containers'
import withPage from '@/lib/page/withPage'

const Blogs = () => {
  return (
    <Fragment>
      <NextSeo title="PDPA Blogs" />

      <BlogFilterProvider>
        <BlogsContainer />
      </BlogFilterProvider>
    </Fragment>
  )
}

export default withPage()(Blogs)
